import React from 'react';
import {Field} from 'formik';

import styles from 'css_modules/ItemCell.module.css';

export const ItemCell = ({children, className, disabled, inputClass, isHidden, isRequired, ...props}) => {
    
    if (props.type === 'checkbox'){
        return (
            <label className={styles.itemcell}>{children}
                <Field className={styles.formikcheckbox} disabled={disabled} {...props}/>
            </label>
        )
    }
    else {
        return (
            <label className={styles.itemcell}>{children}
                <Field className={styles.formikcell} disabled={disabled} {...props}/>
            </label>
        )
    }

   
        
   
    
};