import React from 'react';

import {useRequired} from 'helpers/hooks';
import {Autocomplete} from 'components/Autocomplete';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input';
import {Select} from 'components/Select';
import {useFormikContext} from 'formik';

export const Supplier = ({user,countries, editable, isAccountant, fetchRequiredFields,clientCompanies}) => {
    const {values,validateForm} = useFormikContext(); 
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    

    const identificationNumberRequired = useRequired('supplier_identification_number', requiredFields);
    const vatRequired = useRequired('supplier_vat', requiredFields);
    const titleRequired = useRequired('supplier_title', requiredFields, isAccountant);
    const streetRequired = useRequired('supplier_street', requiredFields);
    const cityRequired = useRequired('supplier_city', requiredFields);
    const zipCodeRequired = useRequired('supplier_zip_code', requiredFields);
    const countryRequired = useRequired('supplier_country', requiredFields);
   

    

    const getUserFriendlyCountries = () => {
        const prefferedcountries = ['cz', 'sk', 'de','pl','at','hu','gb','es','fr','us'];
        let userFriendlyCountries = [];
        
        prefferedcountries.forEach( (pc) => {
            userFriendlyCountries.push(countries[countries.indexOf(countries?.find(curr => curr.slug === pc))]) ;
            }
        )

        const blankCurrency = {"title" : "---", "id" : ""}
        userFriendlyCountries.push(blankCurrency)

        countries.forEach( (curr) => {
                if (!userFriendlyCountries.includes(curr)){
                    userFriendlyCountries.push(curr) ;
                }   
            }
        )

        return userFriendlyCountries
    }
    const userFriendlyCountries = getUserFriendlyCountries()
    
    const companyIdToSearch = parseInt(values.info["client_company"])
    let countrySlug = ''
    if (companyIdToSearch && clientCompanies){
    const foundCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(companyIdToSearch));
    countrySlug = foundCompany ? foundCompany.country.slug : null;
    }
    if (countrySlug === 'sk'){
        return (
            <FieldsWrapper columns={2}>
                <FieldsWrapper columns={1}>
                <Input
                    name="info.supplier_title"
                    isRequired={titleRequired}
                    component={Autocomplete}
                    searchField="title"
                    placeholder="Hledat v databázi"
                    disabled={!editable}
                    onChange={validateForm}
                >
                    Název
                </Input>
                <Input
                    name="info.supplier_street"
                    isRequired={streetRequired}
                    disabled={!editable}
                >
                    Ulice
                </Input>
                <Input
                    name="info.supplier_city"
                    isRequired={cityRequired}
                    disabled={!editable}
                >
                    Město
                </Input>
                <Input
                    name="info.supplier_zip_code"
                    isRequired={zipCodeRequired}
                    disabled={!editable}
                >
                    PSČ
                </Input>
               
                <Input
                    name="info.supplier_country"
                    isRequired={countryRequired}
                    component={Select}
                    data={userFriendlyCountries}
                    disabled={!editable}
                >
                    Země
                </Input>
                
                
            </FieldsWrapper>
                <FieldsWrapper columns={1}>
                <Input
                    name="info.supplier_identification_number"
                    isRequired={identificationNumberRequired}
                    component={Autocomplete}
                    searchField="identification_number"
                    placeholder="Hledat v databázi/ARES"
                    disabled={!editable}
                    onChange={validateForm}
                >
                    IČ
                </Input>
                <Input
                    name="info.supplier_sk_ic_dph"
                    isRequired={false}
                    disabled={!editable}
                >
                    IČ DPH
                </Input>
                <Input
                    name="info.supplier_vat"
                    isRequired={vatRequired}
                    searchField="vat"
                    placeholder="Hledat v databázi"
                    component={Autocomplete}
                    disabled={!editable}
                    onChange={validateForm}
                >
                    DIČ
                </Input>
                
                </FieldsWrapper>
                
            </FieldsWrapper>
        )
    }
    else {
        return (
            <FieldsWrapper columns={2}>
                <Input
                    name="info.supplier_identification_number"
                    isRequired={identificationNumberRequired}
                    component={Autocomplete}
                    searchField="identification_number"
                    placeholder="Hledat v databázi/ARES"
                    disabled={!editable}
                    onChange={validateForm}
                >
                    IČ
                </Input>
                <Input
                    name="info.supplier_vat"
                    isRequired={vatRequired}
                    searchField="vat"
                    placeholder="Hledat v databázi"
                    component={Autocomplete}
                    disabled={!editable}
                    onChange={validateForm}
                >
                    DIČ
                </Input>
                <Input
                    name="info.supplier_title"
                    isRequired={titleRequired}
                    component={Autocomplete}
                    searchField="title"
                    placeholder="Hledat v databázi"
                    disabled={!editable}
                    onChange={validateForm}
                >
                    Název
                </Input>
                <Input
                    name="info.supplier_street"
                    isRequired={streetRequired}
                    disabled={!editable}
                >
                    Ulice
                </Input>
                <Input
                    name="info.supplier_city"
                    isRequired={cityRequired}
                    disabled={!editable}
                >
                    Město
                </Input>
                <Input
                    name="info.supplier_zip_code"
                    isRequired={zipCodeRequired}
                    disabled={!editable}
                >
                    PSČ
                </Input>
                <Input
                    name="info.supplier_country"
                    isRequired={countryRequired}
                    component={Select}
                    data={userFriendlyCountries}
                    disabled={!editable}
                >
                    Země
                </Input>
                
            </FieldsWrapper>
        )
    }
};