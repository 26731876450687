import React from 'react';
import {useFormikContext} from 'formik';

import {useRequired} from 'helpers/hooks';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input'
import {Select} from 'components/Select';
import {useFilter} from "helpers/hooks";
import {DatePicker} from "./DatePicker";

export const Payment = ({user,paymentTypes, accounts, editable, isAccountant, fetchRequiredFields,excludedKeys}) => {
    const {values} = useFormikContext();
    let requiredFields = fetchRequiredFields(user,values?.info["document_type"], values?.info["payment_type"])
    
    const filteredAccounts = useFilter(accounts);
    const accountNumberRequired = useRequired('supplier_account_number', requiredFields);
    const bankCodeRequired = useRequired('supplier_bank_code', requiredFields);
    const variableSymbolRequired = useRequired('variable_symbol', requiredFields);
    const accountOnly = ['fvy', 'fvz', 'opo'].includes(values.info.document_type);
    const isCash = paymentTypes.find(type => type.id === parseInt(values.info.payment_type))?.code === 'cash';
    const accountEditable = editable && values.info.client_company;

    if (values.info["state"]==='tpl'){
        excludedKeys=[]
    }
    return (
        <FieldsWrapper columns={2}>
            <Input
                name="info.payment_type"
                component={Select}
                data={paymentTypes}
                disabled={!editable}
            >
                Forma úhrady
            </Input>
            {accountOnly
                ? (
                    <Input
                        name="info.account"
                        component={Select}
                        data={filteredAccounts}
                        disabled={!accountEditable}
                        isHidden={isCash}
                    >
                        Účet
                    </Input>
                ) : (
                    <>
                        <Input
                            name="info.supplier_account_number"
                            disabled={!editable}
                            isRequired={accountNumberRequired}
                            isHidden={isCash}
                        >
                            Číslo účtu / IBAN
                        </Input>
                        <Input
                            name="info.supplier_bank_code"
                            disabled={!editable}
                            isRequired={bankCodeRequired}
                            isHidden={isCash}
                        >
                            Kód banky / SWIFT
                        </Input>
                    </>
                )}
            <Input
                name="info.variable_symbol"
                disabled={!editable}
                isRequired={variableSymbolRequired}
                isHidden={isCash}
            >
                Variabilní symbol
            </Input>
            {!excludedKeys.includes("payment_date") ? <div style={{ height: '0em' }}>&nbsp;</div> :<Input
                name="info.payment_date"
                component={DatePicker}
                disabled={!editable}
            >
                Datum úhrady
            </Input>}
        </FieldsWrapper>
    )
};