import React, {useEffect, useRef} from 'react';

import styles from 'css_modules/Modal.module.css';

export const Modal = ({closeOnClickOutside, close, large, name, children}) => {
    const containerRef = useRef(null);

    useEffect(() => { // close Modal on click outside
        const handleClickOutside = event =>
            closeOnClickOutside && containerRef.current && !containerRef.current.contains(event.target) && close();
        document.addEventListener('mousedown', handleClickOutside);
        return document.removeEventListener('mousedown', handleClickOutside);
    }, [closeOnClickOutside, close]);

    const size = large ? styles.large : styles.small;

    return (
        <div className={styles.overlay}>
            <div ref={containerRef} className={size}>
                <div className={styles.header}>
                    <h2 className={styles.heading}>{name}</h2>
                    <span onClick={close} className={styles.x}>×</span>
                </div>
                {children}
            </div>
        </div>
    )
};