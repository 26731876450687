import {FieldArray, useFormikContext} from 'formik';
import styles from 'css_modules/Items.module.css';
import {ItemRow} from 'components/ItemRow';
import {ItemsHeader} from 'components/ItemsHeader';
import {Button} from 'components/Button';
import {useFilter} from 'helpers/hooks';
//import { array } from 'yup/lib/locale';
//import {useState} from 'react';
export const Items = (
    {
       usedTaxRates, taxRates, editable, centres,activities,contracts,vatClassification,vatKvClassification,accountCode,doc, docTypes, accountingYears, company,toggleItems
    }
) => {

    let { values} = useFormikContext();
    let filteredTaxRates = taxRates;
    

    let doc_type = values.info.document_type;
    // eslint-disable-next-line
    switch(doc_type) {
        case 'vod':
            doc_type = 'fvy';
            break;
        case 'pod':
            doc_type = 'fpr'
            break;
    }
    
    let vatClsFilterValue = docTypes.find((docType) => docType.slug === values.info.document_type)?.is_expense;        
    let yearFilterValue = accountingYears.find((year) => year.id === parseInt(values.info.accounting_year))?.year;
    let filteredActivities = useFilter(activities, {year: yearFilterValue});
    let filteredCentres = useFilter(centres, {year: yearFilterValue});
    let filteredContracts = useFilter(contracts, {year: yearFilterValue});
    let filteredAccountCode = useFilter(accountCode, {document_type: doc_type});
    let filteredVatClassification = useFilter(vatClassification, vatClsFilterValue === null?{}:{is_expense: vatClsFilterValue});
    let filteredVatKvClassification = useFilter(vatKvClassification, {document_type: doc_type});

    if (company && company.country.slug==='sk'){
        filteredVatClassification = null
    }
    
    
    const emptyCost = (taxRateId) => {
        return {
            tax_rate: taxRateId,
            value_original: '',
            tax: '',
            value_final: ''
        };
    }


    const emptyItem = () => {
        let myRate = undefined
        if (values.costs?.length===0){
            myRate = filteredTaxRates[0].id
            values.costs.push(emptyCost(myRate))
        }
        else{
            myRate = usedTaxRates[0].id
        }
        
        return {
            account_code: values.info.account_code,
            activity: values.info.activity,
            amount_erd: "",
            centre: values.info.centre,
            code: "",
            coefficient: "",
            contract: "",
            cost: "",
            discount_percentage: 0,
            eid: "",
            expiration_date: "",
            external_id: "",
            guarantee: "",
            guarantee_type: "",
            //id: "",
            moss_type: "",
            note: "",
            pdp_code: "",
            price_final:"",
            price_original: "",
            price_vat: "",
            price_unit: "",
            quantity: "1",
            rate_erd: "",
            reverse_charge: "",
            stock_item: "",
            stock_link: "",
            sympar: "",
            tax_rate: myRate,
            text:"",
            unit: "",
            vat_classification: values.info.vat_classification || '',
            vat_kv_classification: values.info.vat_kv_classification || '',
            voucher_eet: ""
        };
    }
   







    if (toggleItems===false && values.items.length ===0){
        return (null)
    }
    let notActivatedMessage = ""
    if (toggleItems===false && values.items.length !==0){
        editable = false
        notActivatedMessage = "(editace položek není k dispozici)"
    }

    if (values.items.length ===0 ){
        return(
            <FieldArray name="items">
                 {arrayHelpers => (
            <>
            <div className={styles.mainwrapper}>
             <div className={styles.topRow}>
            <div className={styles.title}>Položky dokladu</div>
            
            <Button className={styles.button}
               onClick={() =>arrayHelpers.push( emptyItem() )}
               disabled={!editable}
                >
                Přidat položku
            </Button>
        </div>
        </div>
        
        </>
        )}

        
        </FieldArray>
        )
    }
    else{
            return (
                <FieldArray name="items">
                {arrayHelpers => (
                <>
                
            <div className={styles.mainwrapper}>
            <div className={styles.topRow}>
                <div className={styles.title}> Položky dokladu  </div>
                <div className={styles.title}> {notActivatedMessage}</div>
                <div className ={styles.topbuttons}>
                    <Button className={styles.button}
                        onClick={() =>arrayHelpers.push( emptyItem() )}
                        disabled={!editable}
                        >
                        Přidat položku
                    </Button>

                    <Button className={styles.button}
                    onClick={ () => {
                        console.log("Truncating items")
                        let interCosts = []
                        values.costs.forEach(i=>{
                            let c = {}
                            c["value_original"]=i["value_original"]
                            c["tax_rate"]=i["tax_rate"]
                            c["tax"]=i["tax"]
                            c["value_final"]=i["value_final"]
                            
                            interCosts.push(c)
                        })
                        values.costs = interCosts
                        values.items = []

                        values.items.forEach(e=>{arrayHelpers.pop()})
                        arrayHelpers.pop()
                    
                    
                    }
                    }
                        
                    disabled={!editable}
                        >
                        Smazat vše
                    </Button>

                
                </div>

                </div>

            <div className={styles.wrapper}>
                <table className={styles.itemstable}>
                <thead>
                    <tr>
                    <th className={styles.headitemstd1}>
                        <ItemsHeader/>
                    </th>
                    </tr>
                </thead>            

                        <tbody className={styles.itemstablebody}>
                            <tr className={styles.itemstr}>
                                <td className={styles.itemstd1}>
                            
                                {values.items?.map((item, index) =>
                                    <ItemRow
                                        key={index}
                                        editable={editable}
                                        index={index}
                                        taxRates={taxRates}                                
                                        filteredCentres={filteredCentres}
                                        filteredActivities={filteredActivities}
                                        filteredContracts={filteredContracts}
                                        filteredVatClassification={filteredVatClassification}
                                        filteredVatKvClassification={filteredVatKvClassification}
                                        filteredAccountCode={filteredAccountCode}
                                        doctype={values.info.document_type}
                                    
                                    />
                                )}
            
                                </td>
                            </tr>
                        </tbody>
                    </table>  
                </div>
                </div>

                
                </>

        )}

                
        </FieldArray>
    )

}


};