import React, {useEffect, useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {useToast} from 'helpers/hooks';

import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {FieldsWrapper} from 'components/FieldsWrapper';
import {Input} from 'components/Input';
import {Select} from 'components/Select';
import styles from 'css_modules/Cost.module.css';

export const Cost = ({cost, editable, index, isAccountant, remove, taxRates, changeTaxRates}) => {
    const {handleChange, setFieldValue, values} = useFormikContext();
    const [taxFilledByHand, setTaxFilledByHand] = useState(false);
    const isRequired =
        isAccountant && ['fpr', 'fvy', 'fvz', 'opo', 'oza', 'pvy', 'ppr'].includes(values.info.document_type);
    const rateValue = taxRates.find(rate => rate.id === parseInt(cost.tax_rate))?.rate;
    const formattedRateValue = rateValue ? parseFloat(rateValue) : null;
    const taxRate = `costs.${index}.tax_rate`;
    const valueOriginal = `costs.${index}.value_original`;
    const tax = `costs.${index}.tax`;
    const valueFinal = `costs.${index}.value_final`;
    const valueOriginalLabel = formattedRateValue !== null ? `Základ bez ${formattedRateValue} %` : 'Základ';
    const valueFinalLabel = formattedRateValue !== null ? `Celkem s ${formattedRateValue} %` : 'Celkem';
    let rate = taxRates?.find(rate => rate.id === parseInt(cost["tax_rate"]))?.rate;
    
    let displayedRates = taxRates
    
    let myrate =  taxRates?.find(rate => rate.id === parseInt(cost.tax_rate))
    if (!myrate || !(myrate.slug==="cz_unknown" )){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'cz_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }
    if (!myrate || !(myrate.slug==="sk_unknown" )){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'sk_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }

   
    
    const orderArrayByRate= ( array) => {
        let orderedArray = []
        let bannedElements = []

        while (orderedArray.length !== array.length){
            let maxKeyVal = -1
            let maxid = -1
            for (let i = 0; i<array.length; i++){
                let a = array[i]
                let keyVal = a.rate
                if (keyVal > maxKeyVal  && !bannedElements.includes(i)){
                    maxKeyVal = keyVal
                    maxid = i
                }
            } 
            orderedArray.push(array[maxid])
            bannedElements.push(maxid)
        }
        return orderedArray
    }

    displayedRates=orderArrayByRate(displayedRates)
 
    const countItemIncidents = () => {
        if (values.items?.length>0){
            //console.log(values.items)
        }
        return  values.items?.filter( item => parseInt(item.tax_rate) === parseInt(cost.tax_rate)).length 
    }

    let lastItemCount = sessionStorage.getItem("itemsassigned"+cost.id);
    let currentItemCount = countItemIncidents()
    sessionStorage.setItem("itemsassigned"+cost.id, currentItemCount);
    
    let rateEditable = currentItemCount>0 ? false : true
    if (editable===false){rateEditable=false}

    useEffect(() => {
        if (cost["tax_rate"] === -1) {
            removeCost()
            return
        }
        if (rate === undefined) {
            setFieldValue(taxRate, taxRates[0].id);
        }
        if (cost.valueOriginal !=='' && cost.valueFinal !=='' && tax!=='' ){
            //do nothing, trust the data from database
        }
        else if (cost.value_original !== '' && cost.valueFinal==='') {
            //compute final value based on tax rate and original value, calculate tax 
            calculateCost(cost.value_original, rate, false, true);
        } else if (cost.value_final !== '') {
            // compute original value based on tax rate and final value, calculate tax
            calculateCost(cost.value_final, rate, taxRate, true, true);
        }
         // eslint-disable-next-line
        },[cost.tax_rate, rate])

    const showToast = useToast();

    
    
    let sumOriginal = 0;
    let sumTax = 0;
    const sumCostValueFromItems = () => {
        sumOriginal = 0;
        sumTax = 0;
        
        values.items?.filter( item => parseInt(item.tax_rate) === parseInt(cost.tax_rate)).forEach(item=> {
            if (String(item.price_original)!=='' && String(item.price_vat)!==''){
                sumOriginal += parseFloat(item.price_original)
                sumTax += parseFloat(item.price_vat)
            }
            
        } )
        sumOriginal = parseFloat(sumOriginal)
        sumTax = parseFloat(sumTax)

        if (cost.value_original !== sumOriginal){
            cost.value_original = sumOriginal.toFixed(2);
            cost.tax = sumTax.toFixed(2)
            cost.value_final = (sumOriginal + sumTax).toFixed(2)
            editable= false;
            
        }
        else(
            console.log('Skipping write')
            )
        if (!sumOriginal ){
            editable= true;
           
        }
    }

    const nullifyCost = () => {
        cost.value_original = 0
        cost.tax = 0
        cost.value_final = 0
    }

    if ( values.items?.filter( item => parseInt(item.tax_rate) === parseInt(cost.tax_rate)).length >0) {
         sumCostValueFromItems()
    }
    else if (parseInt(lastItemCount)>0 && currentItemCount===0){
        nullifyCost()
        lastItemCount = sessionStorage.getItem("itemsassigned"+cost.id);
        currentItemCount = countItemIncidents()
        sessionStorage.setItem("itemsassigned"+cost.id, currentItemCount);
    }
    



    const calculateCost = (inputValue, inputRate, outputOriginal, recalcTax = false) => {
        if (inputRate != null) {
            const input = parseFloat(inputValue);
            const formulaForOriginal = input / ((100 + parseFloat(inputRate)) / 100);
            const formulaForFinal = input + input / 100 * parseFloat(inputRate);
            const result = outputOriginal ? formulaForOriginal : formulaForFinal;
            const outputField = outputOriginal ? valueOriginal : valueFinal;

            setFieldValue(outputField, inputValue === '' ? '' : result.toFixed(2));

            const tax_val = outputOriginal ? - formulaForOriginal + input : formulaForFinal - input;
            if(!taxFilledByHand || recalcTax){
                setFieldValue(tax, tax_val.toFixed(2));
            }
        }
    }

    const calculateCostSum = (valueOriginal, newTax) => {
        if (newTax != null){
            const result = parseFloat(valueOriginal) + parseFloat(newTax);
            setFieldValue(valueFinal, result.toFixed(2))
        }
    }

    const WrappedCalculus = useCallback( calculateCost
        , [valueFinal, valueOriginal, setFieldValue, tax, taxFilledByHand]
    )

    const handleOriginalValueChange = (e) => {
        handleChange(e);
        calculateCost(e.target.value, rate, false);
    };
    const handleFinalValueChange = (e) => {
        handleChange(e);
        calculateCost(e.target.value, rate, true, true);
    };

    const handleTaxRateChange = (e) => {
        setTaxFilledByHand(false);//- isn't fast enough to update state before calculation happens :c
        handleChange(e);
        const newRate = taxRates?.find(rate => rate.id === parseInt(e.target.value))?.rate;
        if (cost.value_original !== '') {
            calculateCost(cost.value_original, newRate, false, true);
        } else if (cost.value_final !== '') {
            calculateCost(cost.value_final, newRate, true);
        }
    }

    const handleTaxChange = (e) => {
        handleChange(e);
        setTaxFilledByHand(e.target.value.length > 0);
        calculateCostSum(cost.value_original, e.target.value)
    }

    const handleBlur = (e, value) => {
        if (e.target.value !== '') {
            setFieldValue(value, parseFloat(e.target.value).toFixed(2));
        }
    }

    const handleFinalBlur = (e, value) => {
        if (e.target.value !== '') {
            setFieldValue(value, parseFloat(e.target.value).toFixed(2));
        }
    }

    useEffect( () => {
        if (cost.value_final && (cost.value_original === '' || cost.value_original === null)) {
            WrappedCalculus(cost.value_final, rate, true);
        } else if (cost.value_original && (cost.value_final === '' || cost.value_final === null)) {
            WrappedCalculus(cost.value_original, rate, false);
        }
    }, [cost.value_final, cost.value_original, rate, WrappedCalculus/*componentdidmount*/])

    const handleOriginalValueBlur = e => handleBlur(e, valueOriginal);
    const handleFinalValueBlur = e => handleFinalBlur(e, valueFinal);
    const handleTaxBlur = e => {
        handleBlur(e, tax);
        if(taxFilledByHand===false){
            setFieldValue(tax, (cost.value_final - cost.value_original).toFixed(2) );
        }
    }


    const removeCost = () =>  {
        console.log('remove')
        remove(index);}

    
    const rejectDelete = () => {
        let taxRateItemIncidents = countItemIncidents()
        if (taxRateItemIncidents>4){
            showToast('Náklad nelze smazat. Je k němu navázáno ' + taxRateItemIncidents + ' položek.');
        }
        else if  (taxRateItemIncidents>1) {
            showToast('Náklad nelze smazat. Jsou k němu navázány ' + taxRateItemIncidents + ' položky.');
        }
        else if (taxRateItemIncidents===1){
            showToast('Náklad nelze smazat. Je k němu navázána jedna položka.');
            
        }
        else{
            ///console.log(taxRateItemIncidents)
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.topRow}>
                <CancelIcon onClick={countItemIncidents()===0 ? removeCost : rejectDelete} className={styles.cancelIcon}/>
            </div>
            <FieldsWrapper columns={4}>
                <Input
                    name={taxRate}
                    component={Select}
                    data={displayedRates}
                    disabled={!rateEditable}
                    isRequired={isRequired}
                    onChange={handleTaxRateChange}
                    notNull={true}
                >
                    Daňová sazba
                </Input>
                <Input
                    name={valueOriginal}
                    type="number"
                    step="0.01"
                    disabled={!editable}
                    isRequired={isRequired}
                    onBlur={handleOriginalValueBlur}
                    onChange={handleOriginalValueChange}
                >
                    {valueOriginalLabel}
                </Input>
                <Input
                    name={tax}
                    type="number"
                    step="0.01"
                    disabled={!editable}
                    isRequired={isRequired}
                    onBlur={handleTaxBlur}
                    onChange={handleTaxChange}
                    min="0"
                >
                    DPH
                </Input>
                <Input
                    name={valueFinal}
                    type="number"
                    step="0.01"
                    disabled={!editable}
                    isRequired={isRequired}
                    onChange={handleFinalValueChange}
                    onBlur={handleFinalValueBlur}
                >
                    {valueFinalLabel}
                </Input>
        </FieldsWrapper>
        
        </div>
    )
};