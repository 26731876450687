import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Dropzone from 'react-dropzone-uploader';
import {Page} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import {useModal, useToast} from 'helpers/hooks';
import {File} from 'components/File';
import {FileModal} from 'components/FileModal';
import {RemoveFileModal} from 'components/RemoveFileModal';
import {Select} from 'components/Select';
import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {ReactComponent as ChevronLeftIcon} from 'assets/svg/chevron_left.svg';
import {ReactComponent as ChevronRightIcon} from 'assets/svg/chevron_right.svg';
import {ReactComponent as MinusIcon} from 'assets/svg/minus.svg';
import {ReactComponent as PlusIcon} from 'assets/svg/plus.svg';
import {ReactComponent as UploadIcon} from 'assets/svg/upload.svg';
import {ReactComponent as ViewIcon} from 'assets/svg/view.svg';
import {ReactComponent as ClockwiseIcon} from 'assets/svg/clockwise_arrow.svg';
import {ReactComponent as CounterClockwiseIcon} from 'assets/svg/counterclockwise_arrow.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/arrow_down.svg';
import {ReactComponent as PrintIcon} from 'assets/svg/printer.svg';

import styles from 'css_modules/FilePreview.module.css';
import printJS from "print-js";

const Layout = (
    {
        doc, file, input, isDocumentsLocation, numPages, pageNumber, previews, refresh, scale, setFile, setPageNumber,
        setScale, rotation, setRotation, canDelete, detailHeight
    }) => {
    if (detailHeight===undefined){detailHeight=false}
    const [viewModal, toggleView] = useModal();
    const [removeModal, toggleRemove] = useModal();
    
    const previous = pageNumber > 1;
    const next = pageNumber < numPages;
    const zoomInEnabled = numPages && scale <= 2;
    const zoomOutEnabled = scale > 1;
    const isAttachment = file && file !== doc?.file_url;
    const previousClassName = previous ? styles.chevronIconActive : styles.chevronIconInactive;
    const nextClassName = next ? styles.chevronIconActive : styles.chevronIconInactive;
    const zoomInClassName = zoomInEnabled ? styles.zoomIconActive : styles.zoomIconInactive;
    const zoomOutClassName = zoomOutEnabled ? styles.zoomIconActive : styles.zoomIconInactive;
    const viewClassName = numPages ? styles.viewIconActive : styles.viewIconInactive;
    const removeClassName = isAttachment && !isDocumentsLocation ? styles.removeIconActive : styles.removeIconInactive;
    const rotateStyles = styles.rotateIcon;


    const handleView = () => numPages && toggleView(doc);
    const handleRemove = () => isAttachment && !isDocumentsLocation && toggleRemove();
    const getPreviousPage = () => previous && setPageNumber(pageNumber - 1);
    const getNextPage = () => next && setPageNumber(pageNumber + 1);
    const zoomIn = () => zoomInEnabled && setScale(scale + .25);
    const zoomOut = () => zoomOutEnabled && setScale(scale - .25);

    const rotateClockwise = () => setRotation( prev=> (prev+90) % 360);
    const rotateCounterClockwise = () => setRotation( prev=> (prev-90) % 360);

    const print = () => {
        if (!file){ return;}
        printJS({printable: file, type: 'pdf', properties:[]});
    }

    function downloadByUrl(url, filename) {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                    let a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", filename);
                    a.click();
                    a.remove();
                }
            );
        });
    }
    
    const download = () => {
        if (!file){ return;}
        downloadByUrl(file, (file.split('/')).pop());
    }

    const getSelectData = () => {
        if (doc && doc.attachments) {
            const options = doc.attachments.map(attachment => ({
                id: attachment.url,
                title: attachment.filename
            }));
            if (doc.file_url) {
                options.unshift({
                    id: doc.file_url,
                    title: 'Soubor dokladu'
                })
            }
            return options
        }
    }
    const handleSelectChange = (event) => {
        setPageNumber(1);
        setFile(event.target.value);
    }

    return (
        <>
            <div className={styles.options}>
                <div className={styles.optionsRow}>
                    <Select
                        data={getSelectData()}
                        disabled={!doc?.attachments?.length}
                        notNull={doc?.file_url}
                        value={file || ''}
                        onChange={handleSelectChange}
                        className={styles.select}
                    />
                    <div className={styles.icons}>
                        <DownloadIcon title="Stáhnout" onClick={download} className={rotateStyles}/>
                        <PrintIcon title="Tisk" onClick={print} className={rotateStyles}/>
                    </div>
                    <div className={styles.icons}>
                        {input}
                        <CancelIcon title="Smazat přílohu" onClick={handleRemove} className={removeClassName}/>
                    </div>
                </div>
                <div className={styles.optionsRow}>
                    <div className={styles.pagesContainer}>
                        <ChevronLeftIcon title="Předchozí stránka" onClick={getPreviousPage} className={previousClassName}/>
                        <span className={styles.pagesText}>{pageNumber || '–'}/{numPages || '–'}</span>
                        <ChevronRightIcon title="Následující stránka" onClick={getNextPage} className={nextClassName}/>
                    </div>
                        <ViewIcon title="Prohlédnout" onClick={handleView} className={viewClassName}/>
                        <PlusIcon title="Přiblížit" onClick={zoomIn} className={zoomInClassName}/>
                        <MinusIcon title="Oddálit" onClick={zoomOut} className={zoomOutClassName}/>
                        <ClockwiseIcon title="Otáčet po směru hodinových ručiček" onClick={rotateClockwise} className={rotateStyles}/>
                        <CounterClockwiseIcon title="Otáčet proti směru hodinových ručiček" onClick={rotateCounterClockwise} className={rotateStyles}/>
                </div>
            </div>
            {previews}
            {viewModal && <FileModal docName={doc.name} close={toggleView} file={file}/>}
            {removeModal && (
                <RemoveFileModal
                    doc={doc}
                    file={file}
                    close={toggleRemove}
                    refresh={refresh}
                    setFile={setFile}
                />
            )}
        </>
    )
};

const Preview = (e) => {
    const {percent, status} = e.fileWithMeta.meta;
    return status === 'uploading' ? <progress max="100" value={percent}/> : null;
};

export const FilePreview = ({doc, refresh, detailHeight,...props}) => {
    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);
    const [scale, setScale] = useState(1);
    const [pdfWidth, setPdfWidth] = useState(null);
    //const [placeholderHeight, setPlaceholderHeight] = useState(null);

    const [rotation, setRotation] = useState(0);
    
    const [myDoc, setMyDoc] = useState(null);
    if (myDoc === null){setMyDoc(doc)}
    

    const containerRef = useRef(null);

    const location = useLocation();

    const showToast = useToast();

    const documentFileUrl = doc?.file_url;
    const isDocumentsLocation = location.pathname.startsWith('/documents');
    const disabled = isDocumentsLocation || !doc
    const uploadClassName = disabled ? styles.uploadIconInactive : styles.uploadIconActive;
    /*
    const placeholderStyle = {
        height: placeholderHeight
    };*/

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            if (entry.target === containerRef.current) {
             // console.log('Container width changed:', entry.contentRect.width);
              setPdfWidth(containerRef.current.clientWidth * 0.96);
              //setPlaceholderHeight(containerRef.current.clientWidth * 1.414);
            }
          }
        });
        resizeObserver.observe(containerRef.current);
        return () => {
          resizeObserver.disconnect();
        };
      }, []);
    

    // reset states when a new document is loaded
    useEffect(() => {
        setFile(documentFileUrl);
        setNumPages(null);
        setPageNumber(documentFileUrl ? 1 : null);
        setScale(1);
        setPdfWidth(containerRef.current.clientWidth * 0.97);
        //setPlaceholderHeight(containerRef.current.clientWidth * 1.414);
    }, [documentFileUrl]);
    // set PDF width and container height according to the container width upon initial render and any resizing
    useEffect(() => {
        const adjustDimensions = () => {
            setPdfWidth(containerRef.current.clientWidth * 0.97);
            //setPlaceholderHeight(containerRef.current.clientWidth * 1.414);
        };
        adjustDimensions();
        window.addEventListener('resize', () => containerRef.current && adjustDimensions());
        window.removeEventListener('resize', adjustDimensions);
    }, []);

    const onLoadSuccess = ({numPages}) => setNumPages(numPages);
    const getUploadParams = ({file}) => {
        const body = new FormData();
        body.append('attachment', file);
        const pathBase = doc.is_active ? 'my_documents' : 'archive';
        return {
            url: `${process.env.REACT_APP_API}/${pathBase}/${doc.uuid}/add_attachment/`,
            headers: {'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT')},
            body
        }
    };



    const handleStatusChange = async (file) => {
        switch (file.meta.status) {
            case 'done':
                refresh();
                return showToast('Příloha byla nahrána')
            case 'error_file_size':
                return showToast('Soubor je příliš velký')
            default:
                return
        }
    };

   
    let myHeight = '63.7vh'
    if (detailHeight===true){
         myHeight='79vh'
    }

    return (
        <div ref={containerRef} className={styles.container}>
            <Dropzone
                LayoutComponent={(layoutProps) => (
                    <Layout
                        doc={doc}
                        isDocumentsLocation={isDocumentsLocation}
                        refresh={refresh}
                        file={file}
                        numPages={numPages}
                        pageNumber={pageNumber}
                        scale={scale}
                        setFile={setFile}
                        setNumPages={setNumPages}
                        setPageNumber={setPageNumber}
                        setScale={setScale}
                        rotation={rotation}
                        setRotation={setRotation}
                        detailHeight={detailHeight}
                        {...props}
                        {...layoutProps}
                    />
                )}
                PreviewComponent={Preview}
                inputContent={<UploadIcon key="key" title="Nahrát přílohu"/>}
                inputWithFilesContent={<UploadIcon key="key"/>}
                getUploadParams={getUploadParams}
                onChangeStatus={handleStatusChange}
                accept="application/pdf, application/xml, application/isdoc,application/octet-stream,"// exemplary files had type = '', but still some of isdocs could be xml or isdoc typed
                maxSizeBytes={31457280}
                disabled={disabled}
                classNames={{
                    inputLabel: uploadClassName,
                    inputLabelWithFiles: uploadClassName,
                }}
            />
            <div className={styles.placeholder} style={{ height: myHeight, overflow:"auto",flexDirection:"column" }}>
                <File file={file} onLoadSuccess={onLoadSuccess}>
                    {pageNumber && (
                        <Page loading={''}
                              pageNumber={pageNumber}
                              scale={scale}
                              width={pdfWidth}
                              className={styles.page}
                              rotate={rotation}
                        />
                    )}
                </File>
            </div>
        </div>
    )
};

