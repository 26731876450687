import React from 'react';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import cs from 'date-fns/locale/cs';

import styles from 'css_modules/DatePicker.module.css';

registerLocale('cs', cs);

export const DatePicker = ({field, form, customOnChange,...props}) => {
        const supportedFormats = [
            'dd/MM/yyyy', 'dd.MM.yyyy', 'dd. MM. yyyy', 'dd MM yyyy', 'dd. MM.yyyy', 'dd.MM. yyyy'
        ];
        const formatDate = date =>
            date && `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
        const handleChange = date => {
            const {onDateChange} = props;
            form.setFieldValue(field.name, formatDate(date));
            if (date && field.name === 'info.taxable_supply_date') { // set due date to taxable supply date + 14 days
                form.setFieldValue('info.due_date', formatDate(new Date(date.getTime() + 12096e5)));
            }
            if(onDateChange){
                onDateChange(field.name, date?formatDate(date):'none');
            }
        };
        return (
            <div className={styles.container}>
                <ReactDatePicker
                    selected={field.value ? new Date(field.value) : form.values[field.name]? new Date(form.values[field.name]):null}
                    onChange={customOnChange? customOnChange:handleChange}
                    dateFormat={supportedFormats}
                    locale='cs'
                    className={styles.input}
                    calendarClassName={styles.calendar}
                    {...props}
                />
            </div>
        )
    }
;