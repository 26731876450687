/* eslint-disable */

/*
account_code: ""
activity: ""
amount_erd: ""
centre: ""
code: ""
coefficient: ""
contract: ""
cost: 152
discount_percentage: ""
eid: ""
expiration_date: "2022-05-27"
external_id: ""
guarantee: ""
guarantee_type: ""
id: 3
moss_type: ""
note: "druhy item ponz"
pdp_code: ""
price_final: "123.00"
price_original: ""
price_vat: ""
price_unit: ""
quantity: ""
rate_erd: ""
reverse_charge: false
stock_item: ""
stock_link: ""
sympar: ""
tax_rate: 2
text: "Tohle je druhy item text"
unit: ""
vat_classification: ""
vat_kv_classification: ""
voucher_eet: ""
*/

import styles from 'css_modules/ItemRow.module.css';
import {FieldArray, useFormikContext} from 'formik';
import {ItemCell} from 'components/ItemCell';
import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {Select} from 'components/Select';
import React, {useEffect} from 'react';

export const ItemRow = ({index, item, editable, taxRates,filteredCentres,filteredActivities,
    filteredContracts,filteredVatClassification,filteredVatKvClassification,
    filteredAccountCode,doctype}) => {

    const {handleChange, setFieldValue, values} = useFormikContext();
    
    //const amount_erd_name = `items.${index}.amount_erd`;
    //const eid_name = `items.${index}.eid`;
    //const expiration_date_name = `items.${index}.expiration_date`;
    //const external_id_name = `items.${index}.external_id`;
    //const guarantee_name = `items.${index}.guarantee`;
    //const guarantee_type_name = `items.${index}.guarantee_type`;
    //const id_name = `items.${index}.id`;
    //const moss_type_name = `items.${index}.moss_type`;
    //const pdp_code_name = `items.${index}.pdp_code`;
    //const rate_erd_name = `items${index}.rate_erd`;
    //const stock_item_name_name = `items.${index}.stock_link`;
    //const voucher_eet_name = `items.${index}.voucher_eet`;
     

    const account_code_name = `items.${index}.account_code`;
    const activity_name = `items.${index}.activity`;
    const centre_name = `items.${index}.centre`;
    const code_name = `items.${index}.code`;
    const coefficient_name = `items.${index}.price_unit`;
    const contract_name = `items.${index}.contract`;
    const cost_name = `items.${index}.cost`;
    const discount_percentage_name = `items.${index}.discount_percentage`;
    const note_name = `items.${index}.note`;
    const price_final_name= `items.${index}.price_final`;
    const price_original_name = `items.${index}.price_original`;
    const price_vat_name = `items.${index}.price_vat`;
    const quantity_name = `items.${index}.quantity`;
    const reverse_charge_name = `items.${index}.reverse_charge`;
    const sympar_name = `items.${index}.sympar`;
    const tax_rate_name = `items.${index}.tax_rate`;
    const text_name = `items.${index}.text`;
    const unit_name = `items.${index}.unit`;
    const vat_classification_name = `items.${index}.vat_classification`;
    const vat_kv_classification_name = `items.${index}.vat_kv_classification`;
    

    let displayedRates = taxRates
    
    let showUnknownRate = false
    for (let i = 0; i<values?.costs?.length; i++){
        let c = values.costs[i]
        if ( String(displayedRates?.find(rate => rate.id === parseInt(c.tax_rate))?.slug).endsWith('cz_unknown')  ){
            showUnknownRate = true
            
        }
        if ( String(displayedRates?.find(rate => rate.id === parseInt(c.tax_rate))?.slug).endsWith('sk_unknown')  ){
            showUnknownRate = true
            
        }
    }
    
    
    
    if (!showUnknownRate){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'cz_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
        rateToRemove = displayedRates?.find(rate => rate.slug === 'sk_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }
    /*
    if (!myrate || !(myrate.slug==="cz_unknown" )){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'cz_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }
    if (!myrate || !(myrate.slug==="sk_unknown" )){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'sk_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }
    */
    
    
    const calculateTax = (priceOriginal) => {
        
        const taxRate =  taxRates?.find(rate => rate.id === parseInt(values.items[index].tax_rate))?.rate;  
        let tax_val =  priceOriginal  * (taxRate )/100.0 

        let price_final_new = parseFloat(priceOriginal) +parseFloat(tax_val) 

        setFieldValue(price_vat_name, tax_val.toFixed(2));
        setFieldValue(price_final_name, price_final_new.toFixed(2));
    }
    
    let reverse_charge_disabled = true
    if (doctype==='fvy' || doctype==='fvz' || doctype==='opo'){
        reverse_charge_disabled = false
    }

    const handleOriginalValueChange = (e) => {
        handleChange(e);
        calculateTax(e.target.value);

        setFieldValue(values.items[index].price_unit, )
        
        if (values.items[index].quantity){
            let newcoef = e.target.value / parseFloat(values.items[index].quantity) * (1+values.items[index].discount_percentage/100)
            setFieldValue(coefficient_name, newcoef );
        }
        else{
            let newcoef = e.target.value 
            setFieldValue(coefficient_name, newcoef );
            setFieldValue(quantity_name, values.items[index].quantity);
        }
    };
    
    const calculateOriginalValue = (q, c,discount) => {
        if (q===null) q = values.items[index].quantity;
        if (c===null) c = values.items[index].price_unit;
        if (!discount ||discount===null) {
            let disc = parseFloat(values.items[index].discount_percentage)
            discount = disc ? disc : 0 
        }
        
        /*
        if (q==='') {
            q = 1;
            setFieldValue(quantity_name, q);
        }*/
        
        if (c===''){c = 0;}
                
        const newPrice = q * c *(1-discount/100);
        
        setFieldValue(price_original_name, newPrice.toFixed(2));
        return newPrice;
    }

    const handleQuantityChange = (e) => {
        handleChange(e);
        let priceOriginal = calculateOriginalValue(e.target.value, null);
        calculateTax(priceOriginal);
    }


    const handleCoefficientChange = (e) => {
        handleChange(e);
        let priceOriginal = calculateOriginalValue(null, e.target.value);
        calculateTax(priceOriginal);
    }

    const handleTaxRateChange = (e) => {
        
        handleChange(e);
        assignCost(e.target.value);
        
        setFieldValue(tax_rate_name, e.target.value);
        values.items[index].tax_rate = e.target.value
        calculateTax(values.items[index].price_original);
    }

   
    const handleDiscountChange = (e) => {
        handleChange(e);
        
        setFieldValue(discount_percentage_name, e.target.value);
        let priceOriginal = calculateOriginalValue(null, null,e.target.value);
        calculateTax( priceOriginal );
    }
  
    const emptyCost = (taxRateId) => {
        return {
            tax_rate: taxRateId,
            value_original: '',
            tax: '',
            value_final: ''
        };
    }

    let assignedCostIndex = 0;
    const assignCost = (currentTaxRate) => {
        for (let i=0; i <values.costs.length; i++){
            if (parseInt(values.costs[i].tax_rate) === parseInt(currentTaxRate) ){
                setFieldValue(cost_name, values.costs[i].id);
                assignedCostIndex = i;
                return;
            }
        }
        values.costs.push(emptyCost(parseInt(currentTaxRate)))
        setFieldValue(cost_name, values.costs[values.costs.length-1].id);
       
    }

    useEffect( () => { 
        if (values.items[index].tax_rate!==''){
            assignCost(values.items[index].tax_rate) 
           // calculateTax(values.items[index].price_original,values.items[index].discount_percentage);
        } 
    },[values.items[index].tax_rate])

    return (
        <FieldArray name="items">
        {arrayHelpers => (
        <table className={styles.itemstable}>
        <tbody>
        <tr>
        <td className={styles.itemstd}>
        {index+1}
        </td>
        <td className={styles.acrosstwocolumns}>
            < td className={styles.nestedcell1}>
                
                    <ItemCell
                        name={code_name}
                        type="text"
                        //placeholder="Kód"
                        disabled={!editable}
                    >
                    </ItemCell>
            </td>
            <td className={styles.nestedcell2}>
                        <ItemCell
                        name={text_name}
                        type="text"
                        placeholder="Název položky"
                        disabled={!editable}
                    >
                    </ItemCell>
            </td>
        </td>

        <td className={styles.itemstd} >
                 <ItemCell
                    name={quantity_name}
                    type="number"
                    step="0.0001"
                    onChange={handleQuantityChange}
                    disabled={!editable}
                >
                    
                </ItemCell>
                </td>
        <td className={styles.itemstd}>
             <ItemCell
                     name={unit_name}      
                     disabled={!editable}         
                >
               
                </ItemCell>
                </td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={coefficient_name}
                    type="number"
                    step="0.01"
                    onChange={handleCoefficientChange}
                    disabled={!editable}
                    
                >
                  
                </ItemCell>
                </td>
        <td className={styles.itemstd}><ItemCell
                    name = {tax_rate_name}
                    component={Select}
                    data={displayedRates}
                    onChange={handleTaxRateChange}
                    notNull={true}
                    disabled={!editable}
                >
                  
                </ItemCell></td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={discount_percentage_name}
                    type="number"
                    step="0.01"
                    min="0"
                    onChange={handleDiscountChange}
                    disabled={!editable}
                >
                
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={price_original_name}
                    type="number"
                    step="0.01"
                    placeholder="Základ bez DPH"
                    onChange={handleOriginalValueChange}
                    disabled={!editable}
                   
                >
                  
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={price_vat_name}
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="Daň"
                    disabled={!editable}
                >
                  
                </ItemCell>
        </td>
        </tr>
        
        <tr>
        <td className={styles.itemstd}>
            {editable===true ? 
                <CancelIcon 
                onClick={() =>arrayHelpers.remove(index)}
                className={styles.cancelIcon}
                />    :null
            }
        
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={note_name}
                    type="text"
                    disabled={!editable}
                    >
                   
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={sympar_name}
                    type="text"
                    disabled={!editable}
                    
                >
                   
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell   
                    name={account_code_name}
                    component={Select}
                    data={filteredAccountCode}
                    disabled={!editable}
                >
                    
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell type="checkbox" name={reverse_charge_name}  
            disabled={reverse_charge_disabled}
            //checked = {!reverse_charge_disabled}
            />
        </td>
        <td className={styles.itemstd}>
                { filteredVatClassification?.length > 0 ?
                <ItemCell
                    name={vat_classification_name}
                    component={Select}
                    data={filteredVatClassification}
                    disabled={!editable}
                   
                >
                </ItemCell> : null}

                { filteredVatKvClassification?.length > 0 ? <ItemCell
                    name={vat_kv_classification_name}
                    component={Select}
                    data={filteredVatKvClassification}
                    disabled={!editable}
                  
                >
                    
                </ItemCell> : null }
        </td>
        <td className={styles.acrosstwocolumnsbottom}>
                <ItemCell
                    name={centre_name}
                    component={Select}
                    data={filteredCentres}
                    disabled={!editable}
                  
                >
               
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={activity_name}
                    component={Select}
                    data={filteredActivities}
                    disabled={!editable}
                  
                >
                    
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
                 <ItemCell
                    name={contract_name}
                    component={Select}
                    data={filteredContracts}
                    disabled={!editable}
                  
                >
                
                </ItemCell>
        </td>
        </tr>
        </tbody>
        </table>
        )}

        
        </FieldArray>
        
    )
};
