import React from 'react';

import {useToast} from 'helpers/hooks';
import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/SaveModal.module.css';

export const MergeModal = ({close,clickedMerge, clickedClose,update,doc,selectedDocs}) => {

const showToast = useToast();
const merge = async  => {
    close();

    const index = selectedDocs.indexOf(String(doc.uuid));
    if (index !== -1) {
        selectedDocs.splice(index, 1);
     }

    request('/add_as_attachments/', 'POST',
     {"document":String(doc.uuid), "attachments":[...selectedDocs]})
     .then(response=> {
        if (response && String(response.status)===String(200)){
            showToast('Úspěsně sloučeno');
            window.location.reload();
        }
        else{
            showToast('Chyba při slučování dokumentů.');
        }
     })
   
};

        return (
            <div className={styles.ontop}>
                <Modal name={'Sloučit doklady'} close={close}>
                <div className={styles.column}><br></br>Sloučit vybrané doklady do tohoto dokladu?</div>
                <div className = {styles.buttons} >
                

                <div className={styles.button}>      
                    <Button onClick={close} >Storno</Button>  
                    </div>
                    
                    <div className={styles.savebutton}>      
                    <Button onClick={merge} primary={true} >Sloučit</Button>  
                    </div>
                        
                
                </div>
                    
                    
                </Modal>
            </div>
        )
    
    
}; 