import React from 'react';

import {dynamicSort} from 'helpers/hooks'

export const Select = ({blank, field, firstOption, displayAttribute, inputClass, notNull, noBlankOption, onlyvalid, ...props}) => {
    if (noBlankOption === undefined){
        noBlankOption = false;
    }

    const preSelectFirst = (field) => {
       // if (field?.name === "costs.0.tax_rate") {
        //    return true;
       // }
        // Regex for all costs currency
        const regex = RegExp('^costs\\W\\d+\\Wcurrency');
        if (regex.test(field?.name)) {
            return true;
        }
        if (field?.name === "info.accounting_year") {
            return true;
        }
    };


    if (field?.name === "costs.0.tax_rate") {
        if (typeof props.data !== 'undefined' && props.data.length > 0) {
            // Sorted descending by rate
            props.data.sort(dynamicSort("-rate"));
            if (props.form.values.costs[0]["tax_rate"] === "") {
                setTimeout(() => {
                    props.form.setFieldValue(field.name, props.data[0]['id']);
                });
            }
        }
    }


    if (field?.name === "info.accounting_year") {
        if (typeof props.data !== 'undefined' && props.data.length > 0) {
            // Sorted descending by year
            props.data.sort(dynamicSort("-year"));
            if (props.form.values.info.accounting_year === "") {
                setTimeout(() => {
                    props.form.setFieldValue(field.name, props.data[0]['id']);
                })
            }
        }
    }

    
    if (preSelectFirst(field)) {
        return (
            <select className={inputClass} {...field} {...props}>
                {props.data && props.data.map(item => {
                    const id = item?.id ?? item?.slug ?? item;
                    const display = (item && item[displayAttribute || 'title']) || item || blank;
                    return <option key={id} value={id}>{display}</option>
                })}
            </select>
        )
    } else{
        return (
            <select className={inputClass} {...field} {...props}>
                {!onlyvalid && !props.customdivider && !notNull && !noBlankOption &&<option key={undefined} value="">{firstOption || '---'}</option>}
                {!onlyvalid && !props.customdivider && noBlankOption && <option key={-1} value={-1}>{firstOption || '---'}</option>}
                {/* <option key={firstKey || undefined} value={firstValue || ""}>{firstOption || '---'}</option> */}
                {props.data && props.data.map(item => {
                    const id = item?.id ?? item?.slug ?? item;
                    const display = (typeof item === "string" ? item : (item && item[displayAttribute || 'title']));
                    const disbld =  (item?.off === 'true');
                    return <option key={id} value={id} disabled={disbld}>{display || blank}</option>
                })}
            </select>
        )
    }
};